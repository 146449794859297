import $ from 'jquery';
export class SmoothScrolling {
	constructor(selectors, containerSelector) {
		if ('scrollRestoration' in history) {
			history.scrollRestoration = 'manual';
		}
		this.ANIMATION_TIME_FOR_SCROLL = 400;
		this.$container = $(containerSelector).filter(function () {
			return $(this).parent().is(':not(.tab-list-content)');
		});
		this.$container.find(selectors.ITEM_SELECTOR).click((e) => this.handleDesktopClick(e));
		this.observerConfig = { attributes: false, childList: true, subtree: true };
		this.observer = new MutationObserver(() => {
			this.scrollToHash();
		});
		this.handleHashChange();
		this.setScrollToElementByHashtag();
		this.hashScrollTimer = null;
	}
	breakScroll() {
		clearTimeout(this.hashScrollTimer);
		this.observer.disconnect();
	}
	handleHashChange() {
		window.addEventListener('hashchange', () => {
			this.setScrollToElementByHashtag();
		});
	}

	setScrollToElementByHashtag() {
		this.scrollToHash();
		this.observer.observe(document.body, this.observerConfig);
		this.hashScrollTimer = setTimeout(() => {
			this.breakScroll();
		}, 60 * 1000);

		document.addEventListener(
			'wheel',
			() => {
				this.breakScroll();
			},
			{ once: true }
		);
	}
	scrollToHash() {
		if (window.location.hash.length) {
			let tabHash = window.location.hash.replace('#', '');
			let $dektopHashtagged = this.$container.find(`[name="${tabHash}"]:first`);
			if ($dektopHashtagged.length) {
				this.scrollToElement($dektopHashtagged);
			}
		}
	}

	handleDesktopClick(e) {
		const pageUrl = window.location.href.split('#')[0];
		const targetUrl = e.target.href.split('#')[0];

		if (pageUrl == targetUrl && typeof e.target.hash != 'undefined' && e.target.hash != '') {
			e.preventDefault();
			let tabHash = e.target.hash.replace('#', '');
			let $dektopHashtagged = this.$container.find(`[name="${tabHash}"]:first`);
			if ($dektopHashtagged.length) {
				this.scrollToElement($dektopHashtagged);
			}
		}
	}

	scrollToElement(element) {
		window.scrollTo({
			top: element.offset().top
		});
	}
}
