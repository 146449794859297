/* eslint-disable no-new */
'use-strict';
import $ from 'jquery';
import { Carousel } from './Class/Carousel';
import { SWHREF } from './Class/SWHREF';
import { SVGPosition } from './Class/SVGPosition';
import { NoBubble } from './Class/NoBubble';

const SELECTORS = {
	INLINE_PROMO_CONTAINER: '.module-container.inline-promo',
	INLINE_PROMO_WRAP: '.inline-promo-wrap',
	SELECT_SELECTOR: '.inline-promo-pager-select .progressDeviceSelect',
	PAGER_SELECTOR: '.inline-promo-pager__item',
	PAGER_ACTIVE_CLASS: 'inline-promo-pager__item--active'
};
const PERSONALIZED_SLIDER_CLASS = 'js-slider';
const SVG_POSITION_OPTIONS = {
	initDelay: 1000
};

const ANIMATION_TIMES = {
	SLIDE_DURATION: 500,
	FADE_IN: 600
};

const INLINE_PROMO_SWIPER_PARAMS = {
	wrapperClass: 'inline-promo__content',
	loop: true,
	slideClass: 'inline-promo-slide',
	prevButton: '.inline-promo-controls-prev',
	nextButton: '.inline-promo-controls-next',
	duration: ANIMATION_TIMES.SLIDE_DURATION,
	onTransitionStart: function (swiper) {
		$(swiper.container)
			.find('.inline-promo-slide')
			.each(function () {
				if ($(this).index() !== swiper.activeIndex) {
					$(this).find('.inline-promo-slide__text-wrap').animate(
						{
							opacity: 0
						},
						ANIMATION_TIMES.FADE_IN
					);
					$(this).find('.inline-promo-slide__image-wrap').animate(
						{
							opacity: 0
						},
						ANIMATION_TIMES.FADE_IN
					);
					$(this).find('.inline-promo-slide__background').animate(
						{
							opacity: 0
						},
						ANIMATION_TIMES.FADE_IN
					);
				}
			});
	},
	onSlideChangeStart: function (swiper) {
		$(swiper.container)
			.find('.inline-promo-slide')
			.each(function () {
				if ($(this).index() === swiper.activeIndex) {
					$(this).find('.inline-promo-slide__text-wrap').animate(
						{
							opacity: 1
						},
						ANIMATION_TIMES.FADE_IN
					);
					$(this).find('.inline-promo-slide__image-wrap').animate(
						{
							opacity: 1
						},
						ANIMATION_TIMES.FADE_IN
					);
					$(this).find('.inline-promo-slide__background').animate(
						{
							opacity: 1
						},
						ANIMATION_TIMES.FADE_IN
					);
				}
			});
	}
};

$(document).ready(function () {
	$(SELECTORS.INLINE_PROMO_CONTAINER).each(function () {
		if ($(this)[0].className.indexOf(PERSONALIZED_SLIDER_CLASS) == -1) {
			new Carousel(this, INLINE_PROMO_SWIPER_PARAMS, SELECTORS);
			new SVGPosition(this, SVG_POSITION_OPTIONS);
			SWHREF.init(this);
			NoBubble.init(this);
		}
	});
});
