import $ from 'jquery';

$(document).ready(function () {
	const DROP_DOWN_OPEN_SELECTOR = '.drop-down-textblock .drop-down-textblock__open';
	const DROP_DOWN_CLOSE_SELECTOR = '.drop-down-textblock .drop-down-textblock__close';
	const DROP_DOWN_CONTENT_SELECTOR = '.drop-down-textblock__content';

	function openTextBlock() {
		let $this = $(this);
		let $content = $this.parent().find(DROP_DOWN_CONTENT_SELECTOR);
		$content.parent().toggleClass('closed');
		$content.animate(
			{
				display: 'block',
				height: 'toggle'
			},
			1000
		);
	}

	function closeTextBlock(e) {
		e.preventDefault();

		let $this = $(this);
		let $content = $this.parent();
		$content.parent().addClass('closed');
		$content.hide();
	}

	$(DROP_DOWN_OPEN_SELECTOR).click(openTextBlock);
	$(DROP_DOWN_CLOSE_SELECTOR).click(closeTextBlock);
});
