/* eslint-disable no-new */
import $ from 'jquery';
import { SVGPosition } from './Class/SVGPosition';
import { SmoothScrolling } from './Class/SmoothScrolling';

const HEART_BEAT_COMPILED_BASE_URI = typeof window.HEART_BEAT_BASE_URI != 'undefined' ? window.HEART_BEAT_BASE_URI : '/szivdobbanas';

const HEART_BEAT_SELECTORS = {
	CONTAINER: '.app-heartbeat',
	ACTIVE_VIEW: '.heartbeat-content--active',
	THANK_YOU_VIEW: '.heartbeat-content--thankyou',
	STATIC_VIEW: '.heartbeat-content--static',
	BEAT_BUTTON: '.beat-it',
	REFRESH_COUNTER_BUTTON: '.refresh-counter',
	COUNTER: '.heartbeat-counter span'
};

class HeartBeat {
	constructor(selectors) {
		let $container = $(selectors.CONTAINER);

		this.$activeView = $container.find(selectors.ACTIVE_VIEW);
		this.$staticView = $container.find(selectors.STATIC_VIEW);
		this.$thankYouView = $container.find(selectors.THANK_YOU_VIEW);
		this.$counter = this.$activeView.find(selectors.COUNTER);

		this.$activeView.find(selectors.BEAT_BUTTON).click((e) => this.handleBeatClick(e));
		this.$thankYouView.find(selectors.REFRESH_COUNTER_BUTTON).click((e) => this.handleRefreshClick(e));

		if ($container.length) {
			this.refreshCounter();
		}
	}

	handleRefreshClick(e) {
		e.preventDefault();
		this.refreshCounter();
	}

	handleBeatClick(e) {
		e.preventDefault();
		this.beatIt();
	}

	beatIt() {
		HeartBeat.updateCounter().then(
			() => this.showThankYouView(),
			() => this.showStaticView()
		);

		/**
		 * Ez itt amolyan fapados dobbanás effect :)
		 */
		/*
		HeartBeat.updateCounter().then((count) => {
			this.$counter.html(count.count);
			setTimeout(() => {
				navigator.vibrate(30);
				setTimeout(() => {navigator.vibrate(20)}, 180);
			}, 30);
		}, (err) => this.showStaticView());*/
	}

	refreshCounter() {
		HeartBeat.getCurrentCount().then(
			(response) => this.handleNewCount(response.count),
			() => this.showStaticView()
		);
	}

	handleNewCount(count) {
		this.$counter.html(count);
		this.showActiveView();
	}

	showActiveView() {
		this.$thankYouView.hide();
		this.$staticView.hide();
		this.$activeView.show();
	}

	showStaticView() {
		this.$thankYouView.hide();
		this.$activeView.hide();
		this.$staticView.show();
	}

	showThankYouView() {
		this.$staticView.hide();
		this.$activeView.hide();
		this.$thankYouView.show();
	}

	static getCurrentCount() {
		return $.getJSON(HEART_BEAT_COMPILED_BASE_URI + '/api/get_sum_count');
	}

	static updateCounter() {
		return $.ajax({
			url: HEART_BEAT_COMPILED_BASE_URI + '/counter?count=1',
			type: 'POST'
		});
	}
}

new HeartBeat(HEART_BEAT_SELECTORS);

const SMOOTHSCROLL_CONTAINER_SELECTOR = '.module-container';

const selectors = {
	ITEM_SELECTOR: '.module-link'
};

$(window).on('load', function () {
	$(SMOOTHSCROLL_CONTAINER_SELECTOR).each(function () {
		new SVGPosition(this);
	});
	setTimeout(() => {
		new SmoothScrolling(selectors, SMOOTHSCROLL_CONTAINER_SELECTOR);
	});
});
