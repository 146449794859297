import $ from 'jquery';
import { BoxHeights } from './Class/BoxHeights';

const VIDEO_GALERY_SELECTOR = '.module-container.video-gallery';
const VIDEO_OVERLAY_SELECTOR = '.video-gallery__overlay';
const VIDEO_DESCRIPTION_SELECTOR = '.video-gallery__description';
const VIDEO_IFRAME_SELECTOR = 'iframe';
const ENABLE_JS_API_URL_POSTFIX = '?enablejsapi=1';

var players = {};

$(VIDEO_GALERY_SELECTOR)
	.find(VIDEO_OVERLAY_SELECTOR)
	.click(function () {
		let $this = $(this);
		let iframe = $this.parent().find(VIDEO_IFRAME_SELECTOR)[0];
		$this.remove();
		var player = players[iframe.id];
		player.playVideo();
	});

$(VIDEO_GALERY_SELECTOR).each(function () {
	let $this = $(this);
	// eslint-disable-next-line no-new
	new BoxHeights({ container: $this, boxSelector: VIDEO_DESCRIPTION_SELECTOR });
});

function onYouTubePlayer() {
	$(VIDEO_GALERY_SELECTOR).each(function () {
		let $this = $(this);
		$this.find(VIDEO_IFRAME_SELECTOR).each(function () {
			this.src += ENABLE_JS_API_URL_POSTFIX;
			players[this.id] = new YT.Player(this.id, {});
		});
	});
}
function loadPlayer() {
	if (typeof YT == 'undefined' || typeof YT.Player == 'undefined') {
		var tag = document.createElement('script');
		tag.src = 'https://www.youtube.com/iframe_api';
		var firstScriptTag = document.getElementsByTagName('script')[0];
		firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
		window.onYouTubePlayerAPIReady = function () {
			onYouTubePlayer();
		};
	} else {
		onYouTubePlayer();
	}
}
$(document).ready(function () {
	loadPlayer();
});
