import $ from 'jquery';
import { SWHREF } from './Class/SWHREF';
import { NoBubble } from './Class/NoBubble';

const CLICKABLE_LIST_SELECTOR = '.module-container .clickable-list';

$(document).ready(function () {
	$(CLICKABLE_LIST_SELECTOR).each(function () {
		SWHREF.init(this);
		NoBubble.init(this);
	});
});
