import $ from 'jquery';
import { SVGPosition } from './Class/SVGPosition';

const HEADER_PROMO_CONTAINER_SELECTOR = '.module-container.header-promo';
const SVG_POSITION_OPTIONS = {
	initDelay: 1000
};
$(HEADER_PROMO_CONTAINER_SELECTOR).each(function () {
	// eslint-disable-next-line no-new
	new SVGPosition(this, SVG_POSITION_OPTIONS);
});
