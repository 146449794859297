import $ from 'jquery';
import { SVGPosition } from './Class/SVGPosition';
import { SWHREF } from './Class/SWHREF';
import { NoBubble } from './Class/NoBubble';

const PROD_OFFER_CONTAINER_SELECTOR = '.module-container .prod-offer';

$(PROD_OFFER_CONTAINER_SELECTOR).each(function () {
	// eslint-disable-next-line no-new
	new SVGPosition(this);
	SWHREF.init(this);
	NoBubble.init(this);
});
