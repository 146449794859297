import $ from 'jquery';
// import 'image-scale'
import { BoxHeights } from './Class/BoxHeights';
import { SVGPosition } from './Class/SVGPosition';

const CLASS_NAMES = ['.multi-column-descriptive__super-title', '.multi-column-descriptive__sub-title'];

const CONTAINER_SELECTOR = '.module-container.multi-column-descriptive';

$(CONTAINER_SELECTOR).each(function () {
	CLASS_NAMES.forEach((item) => new BoxHeights({ container: this, boxSelector: item }));
	// eslint-disable-next-line no-new
	new SVGPosition(this);
});
