/* eslint-disable no-new */
import $ from 'jquery';
import { BoxHeights } from './Class/BoxHeights';
import { Slider } from './Class/Slider';
import { SWHREF } from './Class/SWHREF';
import { SVGPosition } from './Class/SVGPosition';
import { NoBubble } from './Class/NoBubble';

const SLIDER_CONTAINER_SELECTOR = '.module-container.connection--slider';
const CONNECTION_CONTAINER_SELECTOR = '.module-container.connection';
const BOXHEIGHTS_SELECTORS = ['.connection__img-wrap', '.connection__title', '.connection__text'];

const CONNECTION_SLIDER_SELECTORS = {
	SLIDER_WRAPPER_SELECTOR: '.connection__content',
	ENABLED_CLASSNAME: 'connection--slider-enabled',
	NEXT_BUTTON_SELECTOR: '.connection-controls-next',
	PREV_BUTTON_SELECTOR: '.connection-controls-prev'
};

const CONNECTION_SLIDER_PARAMS = {
	freeMode: true,
	loop: false,
	loopedSlides: 0,
	scrollbar: '.connection-scrollbar',
	scrollbarHide: false,
	scrollbarDraggable: true,
	mousewheelControl: true,
	slideClass: 'connection__card',
	centeredSlides: false,
	grabCursor: true,
	slidesPerView: 'auto',
	spaceBetween: 0
};

$(document).ready(function () {
	$(SLIDER_CONTAINER_SELECTOR).each(function () {
		new Slider(this, Object.assign({}, CONNECTION_SLIDER_PARAMS), CONNECTION_SLIDER_SELECTORS);
		SWHREF.init(this);
		new SVGPosition(this);
		NoBubble.init(this);
	});

	$(CONNECTION_CONTAINER_SELECTOR).each(function () {
		var container = this;
		BOXHEIGHTS_SELECTORS.forEach((selector) =>
			$(this)
				.find(selector)
				// eslint-disable-next-line max-nested-callbacks
				.each(function () {
					new BoxHeights({ container: container, boxSelector: selector, initDelay: 500 });
				})
		);
		SWHREF.init(this);
		new SVGPosition(this);
		NoBubble.init(this);
	});
});
