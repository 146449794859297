import $ from 'jquery';
import { BoxHeights } from './BoxHeights';

const BASE_MODULE_CONTAINER_SELECTOR = '.module-container';
const BOX_HEIGHTS_REFRESH_EVENT_NAME = 'BoxHeights:refresh';
const BOXHEIGHTS_SELECTORS = ['.connection__img-wrap', '.connection__title', '.connection__text'];

export class TabbedContainer {
	constructor(container, selectors) {
		this.DESKTOP_SELECTOR = selectors.DESKTOP_SELECTOR;
		this.MOBILE_SELECTOR = selectors.MOBILE_SELECTOR;

		let $container = $(container);

		this.LIST_SELECTOR = selectors.LIST_SELECTOR;
		this.ITEM_SELECTOR = selectors.ITEM_SELECTOR;
		this.ITEM_TITLE_SELECTOR = selectors.ITEM_TITLE_SELECTOR;
		this.CONTENT_SELECTOR = selectors.CONTENT_SELECTOR;
		this.ACTIVE_ITEM_SELECTOR = selectors.ACTIVE_ITEM_SELECTOR;
		this.FIRST_LINK_SELECTOR = 'a:first';

		this.ANIMATION_TIME = 400;

		this.ACTIVE_ITEM_CLASS = selectors.ACTIVE_ITEM_CLASS;
		this.$desktop = $container.find(this.DESKTOP_SELECTOR);
		this.$mobile = $container.find(this.MOBILE_SELECTOR);

		this.$desktop.find(selectors.ITEM_SELECTOR).click((e) => this.handleDesktopClick(e));
		this.$mobile
			.find(selectors.ITEM_SELECTOR)
			.find('.tab-list-item__title')
			.click((e) => this.handleMobileClick(e));
		this.setSelectedTabByHashtag();
		this.handleHashChange();
		this.setFirstTabToActiveIfHasNotDefault(selectors);
	}

	static hasVisibleTabContent(tabContents) {
		for (let i = 0; i < tabContents.length; i++) {
			if ($(tabContents[i]).is(':visible')) {
				return true;
			}
		}

		return false;
	}

	setFirstTabToActiveIfHasNotDefault(selectors) {
		let desktopTabContents = this.$desktop.find(selectors.CONTENT_SELECTOR);

		if (!TabbedContainer.hasVisibleTabContent(desktopTabContents)) {
			this.$desktop.find(selectors.CONTENT_SELECTOR).first().show();
			this.$desktop.find(selectors.ITEM_SELECTOR).first().addClass(selectors.ACTIVE_ITEM_CLASS);
		}
	}

	handleHashChange() {
		window.addEventListener('hashchange', () => {
			this.setSelectedTabByHashtag();
		});
	}

	handleDesktopClick(e) {
		e.preventDefault();
		this.setDesktopSelectedTab(e.currentTarget);
	}

	setDesktopSelectedTab(selectedTab) {
		let $elem = $(selectedTab);
		let $animationTime = this.ANIMATION_TIME;
		if ($elem.length) {
			let index = $elem.index();

			this.$desktop.find(this.ACTIVE_ITEM_SELECTOR).removeClass(this.ACTIVE_ITEM_CLASS);
			$elem.addClass(this.ACTIVE_ITEM_CLASS);
			let $content = $(this.$desktop.find(this.CONTENT_SELECTOR)[index]);

			this.$desktop
				.find(this.CONTENT_SELECTOR + ':visible')
				.not('')
				.fadeOut($animationTime, function () {
					$content.fadeIn($animationTime, function () {
						// a trigger nem működik ezért kellett a boxheights ide
						$content.find(BASE_MODULE_CONTAINER_SELECTOR).trigger(BOX_HEIGHTS_REFRESH_EVENT_NAME);
						BOXHEIGHTS_SELECTORS.forEach((selector) => new BoxHeights({ container: $content.find(BASE_MODULE_CONTAINER_SELECTOR), boxSelector: selector, initDelay: 500 }));
						$(document).trigger('TabbedContainer:tabChanged', [$content]);
					});
				});
		}
	}

	handleMobileClick(e) {
		e.preventDefault();
		this.setMobileSelectedTab(e.currentTarget.parentElement);
	}

	scrollToElement(element) {
		let animationTimeForScroll = 500;
		let animationDelay = this.ANIMATION_TIME + 100;
		setTimeout(function () {
			$('html, body').animate(
				{
					scrollTop: element.offset().top
				},
				animationTimeForScroll
			);
		}, animationDelay);
	}

	/**
	 * @param selectedTab: az a dom referencia, amit ki kell nyitna / becsukni
	 * @param isHashOpening: nem zárja be az elemet, akkor se ha esetleg open class lenne rajta,
	 * (oldal betöltésnél, ha aktív class rajta van azon az elemen, amit urlből hash-el kell megnyitni, akkor bezárná az elemet)
	 */
	setMobileSelectedTab(selectedTab, stillOpened) {
		let $elem = $(selectedTab);
		if ($elem.length) {
			let $content = $elem.find(this.CONTENT_SELECTOR);
			this.$mobile.find(this.ACTIVE_ITEM_SELECTOR).not($elem).removeClass(this.ACTIVE_ITEM_CLASS);

			if ($elem.hasClass(this.ACTIVE_ITEM_CLASS) && !stillOpened) {
				$elem.removeClass(this.ACTIVE_ITEM_CLASS);
				// $content.animate({ height: 0 }, this.ANIMATION_TIME);
			} else {
				$elem.addClass(this.ACTIVE_ITEM_CLASS);
				// $content.show();
				// $content.css('height', 'auto');
				$(document).trigger('TabbedContainer:tabChanged', [$content]);
			}
		}
	}

	setSelectedTabByHashtag() {
		if (window.location.hash.length) {
			let tabHash = window.location.hash.replace('#', '');
			let $dektopHashtagged = this.$desktop.find(`[name="${tabHash}"]:first`);
			let $mobileHashtagged = this.$mobile.find(`[name="${tabHash}"]:first`);
			let $desktopTab = this._getDesktopTab($dektopHashtagged);
			let $mobileTab = this._getMobileTab($mobileHashtagged);

			/**
			 * Megnézzük hogy éppen melyik nézetben is vagyunk, és amelyikben NEM, aról leszedjük a name attribútumot (és kinyitjuk a megcímzett tabot)
			 * ugyanis ha 2x szerepel a name-ben ugyanaz, akkor a hashtag-es odaugrás nem működik. Majd a végén
			 * kilőjjük a hash-t, és újra beállítjuk hogy odaugorjon.
			 */

			if ($desktopTab.is(':visible')) {
				$mobileHashtagged.attr('name', '');
				this.setDesktopSelectedTab($desktopTab);
				if ($dektopHashtagged.length > 0) {
					this.scrollToElement($dektopHashtagged);
				}
			} else if ($mobileTab.is(':visible')) {
				$mobileTab.find(this.FIRST_LINK_SELECTOR).attr('name', tabHash);
				$dektopHashtagged.attr('name', '');
				this.setMobileSelectedTab($mobileTab, true);
				if ($mobileHashtagged.length > 0) {
					this.scrollToElement($mobileHashtagged);
				}
			}
		}
	}

	_getDesktopTab(hashedElement) {
		let $closestItem = hashedElement.closest(this.ITEM_SELECTOR);
		if ($closestItem.length) {
			return hashedElement.parent();
		} else {
			let $closestContent = hashedElement.closest(this.CONTENT_SELECTOR);
			let idx = this.$desktop.find(this.CONTENT_SELECTOR).index($closestContent);
			return this.$desktop.find(this.LIST_SELECTOR).find(this.ITEM_SELECTOR).eq(idx);
		}
	}

	_getMobileTab(hashedElement) {
		let $closest = hashedElement.closest(this.ITEM_SELECTOR);
		if ($closest.length) {
			let idx = $closest.parent().find(this.ITEM_SELECTOR).index($closest);
			return this.$mobile.find(this.LIST_SELECTOR).find(this.ITEM_SELECTOR).eq(idx);
		}
		return hashedElement.parent();
	}
}
