/* eslint max-lines: ["error", 300] */

import $ from 'jquery';
import { BoxHeights } from './Class/BoxHeights';
import { Slider } from './Class/Slider';
import PersonalizedModulesApi from './api/PersonalizedModulesApi';
import EventLog from './Class/EventLog';
import { SWHREF } from './Class/SWHREF';

const CET_MODULE_ID = 'M09';
const API_TYPES = {
	DWH: 'dwh',
	PDMP: 'pdmp',
	ATG: 'atg'
};
const SLIDER_CONTAINER_SELECTOR = '.module-container.m09p-device-card--slider';
const TITLE_SELECTOR = '.m09p-device-card-item__title';
const DEVICE_CARD_ITEM = '.m09p-device-card-item';
const LOG_HREF_SELECTOR = '.js-log-href';
const PRODUCT_ID = window.sourceProductId;

const DEVICE_CARD_SLIDER_SELECTORS = {
	SLIDER_WRAPPER_SELECTOR: '.m09p-device-card__content',
	ENABLED_CLASSNAME: 'm09p-device-card--slider-enabled',
	NEXT_BUTTON_SELECTOR: '.m09p-device-card-controls-next',
	PREV_BUTTON_SELECTOR: '.m09p-device-card-controls-prev',
	PERSONALIZED_ID: 'data-personalized-id',
	MODILE_ID: 'data-m09p-module-id'
};

const DEVICE_CARD_SLIDER_PARAMS = {
	freeMode: true,
	loopedSlides: 0,
	scrollbar: '.m09p-device-card-scrollbar',
	scrollbarHide: false,
	scrollbarDraggable: true,
	mousewheelControl: true,
	slideClass: 'm09p-device-card-item',
	centeredSlides: false,
	grabCursor: true,
	slidesPerView: 'auto',
	spaceBetween: 0,
	mousewheelReleaseOnEdges: true
};
const PERSONALIZED_OFFER_URL_SELECTOR = 'data-m09p-personalized-url';
const PERSONALIZED_OFFER_TYPE_SELECTOR = 'data-m09p-personalized-type';
const PERSONALIZED_SLIDER_SELECTORS = {
	SLIDER_CONTAINER_SELECTOR: SLIDER_CONTAINER_SELECTOR,
	CARD_SELECTOR: `.${DEVICE_CARD_SLIDER_PARAMS.slideClass}`
};

let personalizedModulesApi = new PersonalizedModulesApi();

const PERSONALIZED_CARDS_SELECTORS = {
	CARDS_CONTAINER_SELECTOR: '.module-container.m09p-device-card--b',
	SLIDER_CONTAINER_SELECTOR: SLIDER_CONTAINER_SELECTOR,
	CARD_SELECTOR: `.${DEVICE_CARD_SLIDER_PARAMS.slideClass}`
};
const getAttributeValue = (attribute) => {
	return $(`[${attribute}]`).val($(attribute)).attr(attribute);
};

class Service {
	static get apiType() {
		return getAttributeValue(PERSONALIZED_OFFER_TYPE_SELECTOR);
	}

	static get url() {
		let personalizedUrl = getAttributeValue(PERSONALIZED_OFFER_URL_SELECTOR);

		if (Service.apiType == API_TYPES.DWH || Service.apiType == API_TYPES.PDMP) {
			return personalizedUrl;
		} else {
			return '/shop/preng/rest/model/aws/provider/PersonalizedItemProviderActor/getRecommendedAccessories?productId=' + PRODUCT_ID;
		}
	}

	static convertRestData(restData) {
		restData.targetedProducts = { products: restData.recommendedItemModel.recommendedProductList };
		restData.targetedProducts.products.forEach(function (product) {
			product.productName = product.name;
			product.productPictures = product.pictures;
		});
		return restData;
	}

	static getPromotions() {
		if (Service.apiType == API_TYPES.PDMP) {
			return personalizedModulesApi.postModuleInfo(Service.url, CET_MODULE_ID);
		}

		return $.getJSON(Service.url);
	}
}

class PersonalizedStuffs {
	static replaceInTemplate(template, promotion) {
		template = template.trim();
		let imageSrc = '';
		let position = 4;
		let m09pAttr = ' data-personalized-id="' + promotion.productName + '"';
		template = [template.slice(0, position), m09pAttr, template.slice(position)].join('');

		if (promotion.productPictures && promotion.productPictures.length) {
			imageSrc = promotion.productPictures[promotion.productPictures.length - 1].url;
		}

		return template
			.replace(/\{\{title}}/g, promotion.productName)
			.replace(/data-src\=\"\{\{imageSrc\}\}\"/, `src="${imageSrc}"`)
			.replace('{{itemUrl}}', promotion.url);
	}

	static initSlider(selectors) {
		let $container = $(selectors.SLIDER_CONTAINER_SELECTOR);
		if ($container.length) {
			$container = $($container[0]); // ha véletlen többet szerkesztenek be, mivel csak egyszer tud megjelenni
			let promotionsPromise = Service.getPromotions();
			let $template = $container.find(selectors.CARD_SELECTOR);
			let $cardContainer = $template.parent();
			let productNamesArray = [];

			let cardHtmlTemplate = $cardContainer.html();
			$template.remove();

			promotionsPromise.then(
				function (promotions) {
					if (promotions && promotions.targetedProducts) {
						promotions.targetedProducts.products.forEach(function (elem) {
							productNamesArray.push(elem.productName);
						});

						$container.attr('data-personalized-id', productNamesArray);
						if (Service.apiType == API_TYPES.ATG) {
							promotions = Service.convertRestData(promotions);
						}

						promotions.targetedProducts.products.forEach(function (item) {
							$cardContainer.append($(PersonalizedStuffs.replaceInTemplate(cardHtmlTemplate, item)));
						});

						new Slider($container, DEVICE_CARD_SLIDER_PARAMS, DEVICE_CARD_SLIDER_SELECTORS); // eslint-disable-line no-new
						new BoxHeights({ container: $container, boxSelector: TITLE_SELECTOR }); // eslint-disable-line no-new
						$container.css('visibility', '');
						let mySwiper = $(DEVICE_CARD_SLIDER_SELECTORS.SLIDER_WRAPPER_SELECTOR)[0].swiper;
						mySwiper.update();
						PersonalizedStuffs.initDeviceCardLogging();
					} else {
						$container.remove();
					}
				},
				function () {
					$container.remove();
				}
			);
		}
	}

	static initSimpleCards(selectors) {
		let $container = $(selectors.CARDS_CONTAINER_SELECTOR).not(selectors.SLIDER_CONTAINER_SELECTOR);
		if ($container.length) {
			$container = $($container[0]); // ha véletlen többet szerkesztenek be, mivel csak egyszer tud megjelenni
			let promotionsPromise = Service.getPromotions();
			let $template = $container.find(selectors.CARD_SELECTOR);
			let $cardContainer = $template.parent();
			let cardHtmlTemplate = $cardContainer.html();

			$template.remove();

			promotionsPromise.then(
				function (promotions) {
					if (promotions && promotions.targetedProducts) {
						if (Service.apiType == API_TYPES.ATG) {
							promotions = Service.convertRestData(promotions);
						}

						promotions.targetedProducts.products.forEach(function (item, i) {
							if (i < 4) {
								$cardContainer.append($(PersonalizedStuffs.replaceInTemplate(cardHtmlTemplate, item)));
							}
						});

						new BoxHeights({ container: $container, boxSelector: TITLE_SELECTOR }); // eslint-disable-line no-new
						PersonalizedStuffs.initSwhref();
						PersonalizedStuffs.initDeviceCardLogging($container);

						$container.css('visibility', '');
					} else {
						$container.remove();
					}
				},
				function () {
					$container.remove();
				}
			);
		}
	}

	static initSwhref() {
		$(DEVICE_CARD_ITEM).each(function () {
			SWHREF.initLinkEventTrigger(this);
		});
	}

	static initDeviceCardLogging() {
		$(DEVICE_CARD_ITEM).each(function () {
			EventLog.promoButton(this, LOG_HREF_SELECTOR);
		});
	}
}

$(document).ready(function () {
	PersonalizedStuffs.initSlider(PERSONALIZED_SLIDER_SELECTORS);
	PersonalizedStuffs.initSimpleCards(PERSONALIZED_CARDS_SELECTORS);
});
