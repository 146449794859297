import $ from 'jquery';
import { TabbedContainer } from './Class/TabbedContainer';

const CONTAINER_SELECTOR = '.module-container.tabbed-container-extended';

const selectors = {
	DESKTOP_SELECTOR: '.tab-list-desktop',
	MOBILE_SELECTOR: '.tab-list-mobile',
	CONTENT_SELECTOR: '.tab-list-content',
	ITEM_SELECTOR: '.tab-list-item',
	LIST_SELECTOR: '.tab-list',
	ITEM_TITLE_SELECTOR: '.tab-list-item__title',
	ACTIVE_ITEM_SELECTOR: '.tab-list-item--active',
	ACTIVE_ITEM_CLASS: 'tab-list-item--active'
};

$(document).ready(function () {
	$(CONTAINER_SELECTOR).each(function () {
		// eslint-disable-next-line no-new
		new TabbedContainer(this, selectors);
	});
});
